<template>
  <NetworkManagementPage />
</template>

<script>
  import NetworkManagementPage from '@/pages/networkManagement/NetworkManagement.vue';

  export default {
    components: {
      NetworkManagementPage
    },
    name: 'NetworkManagement'
  };
</script>